<template>
  <div class="container">
    <div class="main-content">
      <div class="row">
        <div class="col h5 mt-2">Hansan với kinh nghiệm hơn 10 năm trong lĩnh vực phát triển phần mềm, thử nghiệm và triển khai cho nhiều khách hàng
          trong nước và quốc tế. Chúng tôi, đội ngũ kỹ sư giàu kinh nghiệm phát triển hệ thống trực tuyến sẽ mang đến cho bạn những mô hình quản trị kinh doanh
          an toàn, hiệu quả nhất. </div>
      </div>
      <div class="row">
        <div class="col h2 text-success">1. Tư vấn giải pháp quản trị doanh nghiệp</div>
      </div>
      <div class="row mt-2">
        <ul>
          <li>Hệ thống quản lý Nhân Sự, chấm công tính lương</li>
          <li>Hệ thống quản lý Bán Hàng...</li>
          <li>Hệ thống quản lý Kho bãi, nhà máy....</li>
          <li>Hệ thống quản lý Bệnh viện, phòng khám ngoài giờ, phòng Nha....</li>
        </ul>
      </div>
      <div class="row h5">
        <div class="col h2 text-success">2. Tư vấn giải pháp công nghệ</div>
      </div>
      <div class="row mt-2">
        <ul>
          <li>Hệ thống Quản lý Online, Offline</li>
          <li>Hệ thống chạy trên đa nền tảng, tương thích với thiết bị cầm tay</li>
          <li>Lựa chọn công nghệ tốt và phù hợp nhất cho mô hình phát triển doanh nghiệp</li>
          <li>Vận hành server, cloud, đào tạo triển khai sử dụng</li>
          <li>Tích hợp thiết bị phần cứng ngoại vi, liên kết làm việc với hệ thống</li>
        </ul>
      </div>
      <div class="row h5 text-success">
        <div class="col h2">3. Thiết kế và triển khai hệ thống chuyên biệt cho doanh nghiệp</div>
      </div>
      <div class="row mt-2">
        <ul>
          <li>Khảo sát doanh nghiệp và hệ thống hiện hành (nếu có)</li>
          <li>Thu thập thông tin hệ thống hiện tại</li>
          <li>Lấy yêu cầu khách hàng</li>
          <li>Thiết kế hệ thống mới dựa trên hệ thống hiện tại và yêu cầu khách hàng</li>
          <li>kiểm nghiệm hệ thống và triển khai cài đặt</li>
          <li>Vận hành server và bảo trì hệ thống đảm bảo hoạt động tốt</li>
          <li>Đào tạo và hướng dẫn sử dụng hệ thống</li>
        </ul>
      </div>
      <div class="row">
        <div class="col">
          <div class="h2 text-success">4. Tầm quan trọng của nền công nghiệp 4.0</div>
          <div> Ngày nay Công Nghệ số 4.0 đã trở thành xu thuế của nhân loại. Việc vận dụng tốt nó sẽ mang lại rất nhiều lợi ích cho doanh nghiệp.
            Nó không những giúp tăng năng suất lao động, tăng doanh thu mà còn giúp doanh nghiệp vươn xa hơn đến những khách hàng tiềm năng.
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-3">
          <div class="h2 text-info">Tính hiệu quả</div>
          <div class="text-muted">Là doanh nghiệp tính hiệu quả rất quan trọng, hiệu quả của hoạt động doanh nghiệp, hiệu quả trong xử lý vấn đề,
            do đó sử dụng hệ thống cũng cấp
            những công cụ, tiện ít phù hợp với nhu cầu công việc sẽ mang lại năng suất cao hơn, moi hoạt động sẽ dể dàng và nhanh chóng hơn, giúp doanh nghiệp tăng
            doanh thu, giảm giờ làm nhân viên </div>
        </div>
        <div class="col-md-3">
          <div class="h2 text-info">Tính tức thời</div>
          <div class="text-muted">Thời gian là tiền bạc, và nó càng quan trọng hơn trong công ty, doanh nghiệp, tất cả mọi hành động đều cần được phản hồi
            nhanh nhất và hiệu quả nhất, hệ thống tốt sẽ giúp mọi người trong doanh nghiệp tương tác với nhau chặt chẽ, giảm thiểu sai sót.
          </div>
        </div>
        <div class="col-md-3">
          <div class="h2 text-info">Tính an toàn</div>
          <div class="text-muted">Với công ty, doanh nghiệp dữ liệu là tài sản vô cùng quý giá, do đó việc bảo mật dữ liệu an toàn tuyệt đối là nhu cầu hàng đầu,
            và với công nghệ hiện đại ngày nay dữ liệu được lưu trữ trên đám mây vừa an toàn, vừa có thể chia sẽ mọi lúc mọi nơi cho người dùng.
          </div>
        </div>
        <div class="col-md-3">
          <div class="h2 text-info">Tính tiện dụng</div>
          <div class="text-muted">Làm việc mọi nơi với thiết bị cầm tay có kết nối mạng, không bị ảnh hưởng khoản cách địa lý. Giúp khách hành, chủ doanh nghiệp, nhân viên làm việc đồng thời, dữ liệu được chia sẽ trên thời gian thực</div>
        </div>
      </div>
      <div class="row border border-light mb-1">
        <div class="col fb-comments"
        data-numposts="5" data-width="100%" data-lazy="true"></div>
      </div>
    </div>
  </div>
</template>
<style>
</style>
<script>
import { VueAgile } from 'vue-agile'
export default {
  name: 'Gioithieu',
}
</script>
